
jQuery(document).ready(function() {

    function setPageTopMargin() {
        nav_height = jQuery('.full_nav_container').outerHeight();
        // if(device_is_desktop) {
        //     nav_height = jQuery('.full_nav_container').outerHeight();
        // } else {
        //     nav_height = jQuery('.surnav').outerHeight() + jQuery('.navbar_container').outerHeight();
        // }

        jQuery('.site-content').css('margin-top', nav_height+'px');
    }
    setPageTopMargin();
    
    jQuery(window).resize(function () { 
        setPageTopMargin();
    });
});