jQuery(document).ready(function() {
    if(jQuery('.full_nav_container .info-bar').length > 0){

        const targetSpeed = 100;
        const infoBar = document.querySelector('.full_nav_container .info-bar');
        const phrases = document.querySelectorAll('.full_nav_container .info-bar .phrase');

        if(phrases.length) {

            function updateAnimationDuration() {
                let infoBarWidth = infoBar.offsetWidth;
                let animationDuration = infoBarWidth / targetSpeed;
                if (animationDuration < 7) {
                    animationDuration = 7;
                }

                phrases.forEach(phrase => {
                    phrase.style.setProperty('--scroll-duration', `${animationDuration}s`);
                });
            }

            let currentPhraseIndex = 0;
            const animatePhrase = () => {
                phrases.forEach(phrase => {
                    phrase.classList.remove('animate');
                });

                phrases[currentPhraseIndex].classList.add('animate');

                phrases[currentPhraseIndex].addEventListener('animationend', () => {
                    currentPhraseIndex++;
                    if (currentPhraseIndex >= phrases.length) {
                        currentPhraseIndex = 0; 
                    }
                    animatePhrase();
                }, { once: true });
            };

            updateAnimationDuration();
            animatePhrase();

            window.addEventListener('resize', () => {
                updateAnimationDuration();
            });
        }
    
    }
});